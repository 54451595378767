<template>
  <el-select
    :value="selected"
    multiple
    collapse-tags
    @input="dispatch"
    :placeholder="placeholder || $t('message.phoneNumberForSipAccountOutsideCall')"
    :size="size"
    class="w-100"
    filterable 
    clearable
  >
    <el-option
      v-for="(item, index) in items"
      :key="'item-' + index"
      :label="item.name + ': ' +item.phone_number"
      :value="item.id"
    >
    </el-option>
  </el-select>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    size: {
      default: "small",
    },
    placeholder: {
      default: null,
    },
    item_ids: {
      default: Array,
    },
  },
  watch: {
    item_ids: {
      handler: function () {
        this.selected = this.item_ids;
      },
      immediate: true,
    },
  },
  data() {
    return {
        selected: [],
    };
  },

  mounted() {
    if (this.items && this.items.length === 0) this.updateInventory();
  },
  computed: {
    ...mapGetters({
      items: "phoneNumberForSipAccountOutsideCall/inventory",
    }),
  },
  methods: {
    ...mapActions({
      updateInventory: "phoneNumberForSipAccountOutsideCall/inventory",
    }),
    
    dispatch(e) {
      this.$emit("input", e);
      this.selected = e;
    },
  },
};
</script>